import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Header from '../components/header'
import Container from '../components/container'
import Row from '../components/row'
import Column from '../components/column'

const PageHeader = <Header title="Zertifikate" />

const ZertifikatePage = ({ location }) => {
    const data = useStaticQuery(graphql`
    {
        certificatePreviews: allFile(filter: {relativeDirectory: {eq: "certificate_previews"}}, sort: {fields: name}) {
            nodes {
            childImageSharp {
                gatsbyImageData(
                width: 480
                quality: 80
                transformOptions: {
                    cropFocus: CENTER
                }
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                )
            }
        }
        }
        certificates: allFile(
            filter: {
                sourceInstanceName: {eq: "documents"}, 
                relativeDirectory: {eq: "certificates"}, 
                extension: {eq: "pdf"}
            }, 
            sort: {fields: name}
        ) {
            nodes {
                name
                publicURL
            }
        }
    }
    `)

    return (
        <Layout
            translatedPageLinks={[{ code: 'de', link: '/de/zertifikate/' }, { code: 'en', link: '/en/certificates/' }]}
            pageLang="de"
            pageTitle="Zertifikate"
            pageHeader={PageHeader}
            location={location}
        >
            <section>
                <Container>
                    <p>Wir sind ein zukunftsorientiertes Unternehmen und nehmen unsere Verantwortung gegenüber unseren Kunden und der Umwelt sehr ernst.</p>
                    <p>
                        Unser hoher Qualitätsanspruch und unser Bestreben nach Nachhaltigkeit und umweltbewusstem Handeln stehen im Fokus unserer Arbeit.
                        Aus diesem Grund sind wir nach <strong>DIN EN ISO 14001:2015 Umwelt&shy;management</strong> sowie nach <strong><span style={{ whiteSpace: 'nowrap' }}>DIN EN ISO 9001:2015</span> Management&shy;system</strong> zertifiziert.
                    </p>
                    <Row>
                        {data.certificates.nodes.map((certificate, index) =>
                            <Column columnWidth={6} key={certificate.name}>
                                <div className="file-preview" data-aos="fade-up" data-aos-duration={200 + (200 * index)}>
                                    <a href={certificate.publicURL} target="_blank" rel="noreferrer">
                                        <GatsbyImage image={getImage(data.certificatePreviews.nodes[index])} alt={certificate.name} />
                                    </a>
                                </div>
                            </Column>
                        )}
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export default ZertifikatePage